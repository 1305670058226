import React, { Component } from 'react';
import { Button, Row, Container, Col } from 'react-bootstrap';
import './games.scss';
import screenshot1 from '../../assets/dice-and-roll-1-map.png';
import screenshot2 from '../../assets/dice-and-roll-1-battle.png';
import screenshot3 from '../../assets/dice-and-roll-1-loading.png';
import screenshot4 from '../../assets/dice-and-roll-2-logo.png';
import screenshot5 from '../../assets/dice-and-roll-2-battle.png';
import team from '../../assets/dice-and-roll-team.jpg';
import ImageModal from '../../components/imageModal/imageModal';
import { Dice5Fill } from 'react-bootstrap-icons';
import alchemist from '../../assets/alchemist-head.png';
import fey from '../../assets/fey.png';
import assasin from '../../assets/assasin2.png';
import automaton from '../../assets/automaton2.png';

export default class Games extends Component {
  render() {
    return (
      <section id="games">
        <div className="banner-text">
          <h1>Games</h1>
        </div>
        <Container className="panel">
          <Row>
            <Col lg={12}>
              <h3>Dice & Roll</h3>
              <span className="motto">
                A role-playing game written in pure JavaScript and Phaser
              </span>
              <p>
                <em>
                  There is one friend of mine that I very much appreciate, and
                  his name is <b>Nikola Begedin</b>. Nikola and I have been
                  working in a company together, and burning our working hours
                  on various JavaScript projects. I've known Nikola for quite
                  some time, and we spent time talking about different games we
                  like, how it would be a great idea to make a simple game
                  together - just to prove ourselves that we can do it. Working
                  everyday job for business companies is usually a hussle, so we
                  agreed to break that monotony with studying
                  <b>
                    game development with
                    <a href="https://phaser.io/" target="_blank" rel="noreferrer">
                      Phaser
                    </a>
                  </b>{" "}
                  - a JavaScript-based framework.
                  <br></br>
                  The product of our free-time laboring was our joint game, that
                  we called <b>"Dice & Roll"</b>, since we wanted to make a game
                  that involved some simple mechanics with some bit of a chance
                  factor. Graphics for the game was made by my oldest son
                  <b>Tvrtko Ćosić</b> - at that time a young teenager, and now a
                  talented biologist!
                  <br />
                  Anyways, we've enjoyed time spent together, and there was even
                  some memorable product of that time.
                </em>
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <ImageModal src={screenshot1} width="360px"></ImageModal>
              <ImageModal src={screenshot2} width="360px"></ImageModal>
              <ImageModal src={screenshot3} width="360px"></ImageModal>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Button
                variant="secondary"
                size="sm"
                className="m-2"
                onClick={() =>
                  window.open(
                    "https://happy-beach-0cf272503.4.azurestaticapps.net",
                    "blank"
                  )
                }
              >
                <Dice5Fill></Dice5Fill>&ensp; Play Dice&Roll Game
              </Button>
            </Col>
          </Row>
        </Container>
        <Container className="panel">
          <Row>
            <Col lg={12}>
              <h3>Dice & Roll 2 - A Lighthouse Tale</h3>
              <span className="motto">
                A second installment, this time, written in TypeScript and
                Phaser 3.2 with WebPack
              </span>
              <p>
                <em>
                  It was the summer of 2018, when me and my kind were enjoying
                  fresh mountain air in Gorski Kotar region of Croatia, while an
                  idea came to our minds: make our own game!
                  <br />
                  Although, I was at time already a senior developer, it didn't
                  take me much to be convinced that it would be actually a good
                  idea to spend some time together on activity that we're
                  already very fond off - gaming. I've already done some game
                  dev stuff before, but I really didn't think I would continue
                  to pursue this endeavour ever again. My kids took this
                  initiative very seriously and they spend their entire summer
                  vacation days thinking and talking about it. <br />
                  So, we started designing a game that would be a continuation
                  of the <b>"Dice & Roll" story</b>, but now with better
                  graphics, story and mechanics. My daughter, <b>Klara Ćosić</b>{" "}
                  started to take an interest in arts and graphics design, so I
                  persuaded her to join the team, and she created some very nice
                  images for the game. Tvrtko provided good ideas for the game
                  concept, together with a world map (in that time, we played
                  D&D on occasional basis). And finally, my youngest son,{" "}
                  <b>Šimun Ćosić</b> tested and optimized the game mechanics.
                  Klara is now a media university graduate, and Šimun has
                  started a software engineering university.
                </em>
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <ImageModal src={screenshot4}></ImageModal>
            </Col>
            <Col lg={6}>
              <ImageModal src={screenshot5}></ImageModal>
            </Col>
          </Row>
          <Row>
            <Col lg={8} xs={12}>
              <Row>
                <Col lg={3} xs={6}>
                  <ImageModal src={alchemist}></ImageModal>
                  <ImageModal src={automaton}></ImageModal>
                </Col>
                <Col lg={4} xs={6}>
                  <ImageModal src={assasin}></ImageModal>
                </Col>
                <Col lg={5} xs={12}>
                  <ImageModal src={fey}></ImageModal>
                </Col>
              </Row>
            </Col>
            <Col lg={4} xs={12}>
              <ImageModal src={team}></ImageModal>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Button
                variant="secondary"
                size="sm"
                className="m-2"
                onClick={() =>
                  window.open(
                    "https://black-water-0e4bf5e03.4.azurestaticapps.net",
                    "blank"
                  )
                }
              >
                <Dice5Fill></Dice5Fill>&ensp; Play Dice&Roll 2 Game
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}