import React, { Component } from 'react';
import './sessions.scss';

export default class Sessions extends Component {
  render() {
    return (
      <section id="sessions">
        <div className="banner-text">
          <h1>Sessions</h1>
        </div>
        <p>
          You can regularly find me either as a <b>speaker</b> on regional IT
          conferences, or as an eager attendee on many IT conferences around the
          world.
        </p>
        <p>
          <small>(content coming soon!)</small>
        </p>
      </section>
    );
    }
  }