import React, { Component } from 'react';
import './header.scss';
import { Navbar, Container, Nav } from "react-bootstrap";

export default class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <header id="home">
          <Navbar id="nav-wrap" expand="lg">
            <Container>
              <Navbar.Toggle id="navbar-toggle" aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="#home">Home</Nav.Link>
                  <Nav.Link href="#about">About</Nav.Link>
                  <Nav.Link href="#games">Games</Nav.Link>
                  <Nav.Link href="#projects">Projects</Nav.Link>
                  <Nav.Link href="#sessions">Sessions</Nav.Link>
                  <Nav.Link href="#contact">Contact</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>          
          <div className="row banner">
            <div className="banner-text">
              <h1 className="responsive-headline">NoBeard</h1>
              <span className="h3">Mentoring teams, architecting dreams</span>
              <div className="header-text">
                <h3>
                  What do I stand for?<br></br>Well, it's all about people...
                </h3>
                <hr />
                <span className="motto">
                  I invest my time and effort, working with great passion and
                  commitment, to help people in organizations to master their
                  technology skills, strive for knowledge, and to encourage
                  companies to use their full team potential.<br></br> I treat
                  every organization, team, and person individually, taking
                  their specialty and given concrete context in mind. Through
                  careful technology guidance, I lead teams to make a difference
                  working productive on projects and initiatives, thus
                  increasing their personal and company value.
                </span>
                <br></br>
                <span className="motto">
                  I base my professional and personal life on faith in God, and
                  I rely my whole life on His eternal wisdom. <br></br>I believe
                  that, with an open heart and compassion, with support and
                  understanding of people, we make the lives of others fruitful
                  and purposeful.
                </span>
                <hr />
                <ul className="social">
                  <li key="linkedin-company">
                    <a
                      href="https://www.linkedin.com/company/nobeard/about/?viewAsMember=true"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li key="github">
                    <a
                      href="http://github.com/dedalusmax"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-github"></i>
                    </a>
                  </li>
                  <li key="linkedin-personal">
                    <a
                      href="https://www.linkedin.com/in/ratko-cosic/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="scrolldown">
            <a className="smoothscroll" title="Go Down" href="#about">
              <i className="fa fa-chevron-down"></i>
            </a>
          </div>
        </header>
      </React.Fragment>
    );
  }
}