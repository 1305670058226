import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './imageModal.scss'; // Import the CSS file
import { Image } from 'react-bootstrap';

interface ImageModalProps {
  src: string;
  width?: string;
  noZoom?: boolean;
}

const ImageModal: React.FC<ImageModalProps> = ({ src, width, noZoom }) => {

  if (width == null) {
    width = '100%';
  }

  if (noZoom == null) {
    noZoom = false;
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  
  function handleShow()
  {
    if (window.matchMedia('(min-width: 600px)').matches && !noZoom) {
        setShow(true);
    }
  }

  return (
    <>
      <Image src={src} alt="" rounded onClick={handleShow} className="responsive" style={{ cursor: 'pointer', width: width, display: 'inline' }} />
      
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <Image rounded src={src} alt="" className="zoomed-image" onClick={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImageModal;
