import React, { Component } from 'react';
import './contact.scss';
import logo from '../../assets/logo.jpg';

export default class Contact extends Component {
  render() {
    return (
      <section id="contact">
        <div className="banner-text">
          <h1>Contact</h1>
        </div>
        <div className="company">
          <div className="title">NOBEARD</div>
          <p>
            <span>Računalne djelatnosti /</span> IT consulting and services
          </p>
          <p>
            <span>vl. /</span> owned by: <b>Ratko Ćosić</b>
          </p>
          <p>
          <span>OIB /</span> VAT ID: <b>36673788357</b>
          </p>
          <ul className="social">
            <li key="linkedin-company">
              <a
                href="https://www.linkedin.com/company/nobeard/about/?viewAsMember=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
          </ul>
          <div>
            <img src={logo} className="logo" alt="logo" />
          </div>
          <p>IBAN: <b>HR3723600003119478770</b></p>
          <p>SWIFT BIC: <b>ZABAHR2X</b></p>
        </div>
      </section>
    );
  }
}