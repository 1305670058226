import React, { Component } from 'react';
import { Badge, Button, Row, Container, Col } from 'react-bootstrap';
import logoext from '../../assets/ext-translate-logo.jpeg';
import screenshot from '../../assets/ext-translate-screenshot.png';
import './projects.scss';
import ImageModal from '../../components/imageModal/imageModal';

export default class Projects extends Component {
  render() {
    return (
      <section id="projects">
        <div className="banner-text">
          <h1>Projects</h1>
        </div>
        <Container>
          <hr />
          <Row>
            <Col lg={12}>
              <div className="article p-2">
                <div>
                  <ImageModal src={logoext} width="120px" />
                </div>
                <div>
                  <h1>Azure Translator API Action Button</h1>
                  <Badge bg="secondary" className="m-2">
                    Azure DevOps
                  </Badge>
                  <Badge>Azure AI Translator API</Badge>
                  <article>
                    A configurable extension that provides an action button for
                    translating fields by using Azure AI Translator API.
                  </article>
                </div>
                <ImageModal src={screenshot} width="800px"></ImageModal>
                <Button
                  variant="outline-warning"
                  size="sm"
                  className="m-2"
                  onClick={() =>
                    window.open(
                      "https://marketplace.visualstudio.com/items?itemName=NoBeard.azure-translator-api-extension",
                      "blank"
                    )
                  }
                >
                  Go to Azure Marketplace
                </Button>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() =>
                    window.open(
                      "https://github.com/dedalusmax/translate-ext",
                      "blank"
                    )
                  }
                >
                  See the Code on GitHub <i className="fa fa-github ma-2"></i>
                </Button>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg={12}>
              <div className="p-2">
                <h1>C#/.NET Learning Projects</h1>
                <blockquote>coming soon!</blockquote>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg={12}>
              <div className="article p-2">
                <ImageModal src={logoext} width="120px" />
                <div>
                  <h1>Live Chef</h1>
                  <Badge bg="secondary" className="m-2">
                    Angular
                  </Badge>
                  <Badge>TypeScript</Badge>
                  <Badge bg="danger" className="m-2">
                    WebRTC
                  </Badge>
                  <article>
                    This application is used as demo. This project was developed
                    with Angular CLI version 1.4.2. using WebRTC. The subject of
                    the demo is what if in the kitchen something goes wrong or
                    there aren't ideas for what to cook.
                  </article>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}