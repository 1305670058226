import React, { Component } from 'react';
import './footer.scss';

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="scrollup">
          <a className="smoothscroll" title="Back to Top" href="#home">
            <i className="fa fa-chevron-up" />
          </a>
        </div>
        <div className='article'>
          Location:
          <br />
          Kružna 14, Beletinec
          <br />
          42214 Sveti Ilija, HR
        </div>
        <div>
          Contact via e-mail:
          <br />
          <a
            href="mailto:ratko@nobeardhrvatska.onmicrosoft.com"
            className="App-link"
          >
            ratko@nobeardhrvatska.onmicrosoft.com
          </a>
          <br />
          <a href="mailto:ratko_cosic@hotmail.com" className="App-link">
            ratko_cosic@hotmail.com
          </a>
        </div>        
      </footer>
    );
  }
}